import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import styles from './index-page.css'
import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'

import ReactPlayer from 'react-player'
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  uploadMemory,
  loadPlayer
}) => (
  <div>
    <header>
      <p className="meta">In memory of Parker Bagley</p>
      <h1>Parker Bagley's Excellent Adventure</h1>
      <h2>A Celebration of Life</h2>
    </header>
    
    <main>
      <div>
      <h2>Memorial Fund</h2>
        <p>
          Parker was a lifelong musician and performer. 
          Please honor Parker with a donation to an organization closely aligned with his passion, Todd Rundgren’s foundation devoted to supporting music education for children
        </p>
        <p><a href="https://spiritofharmony.org/parkerbagley/" target="_blank">https://spiritofharmony.org/parkerbagley/</a></p>
        {/* <h2>Join Us</h2>
        <p>Please join Parker's family for a Celebration of Life for Parker Theodore Bagley.</p>
        <p>Let's celebrate the brilliant mind, heart and life of Parker, together.</p>
        <div className="zoom">
          <a href="https://us02web.zoom.us/j/84340953676?pwd=KzRlT2UxRFZONEV4WXN4N3BWcXllUT09">Click here to join the Zoom</a>
        </div>
        <p>Zoom will start at 1:45pm EST on Saturday.</p> */}
      </div>
      <div>
        <figure>
          <img src="img/parker.jpg"/>
          <figcaption>August 30, 1989 - June 17, 2020</figcaption>
        </figure>
      </div>
    </main>
    
    <div className="other">
      <div>

        {/* <div class="preview" onClick="">
          <img src='https://d2hk0ygkid9qvv.cloudfront.net/ceremony-preview.png' onClick={loadPlayer}/>
        </div> */}
        
        <h2>Watch the Virtual Ceremony</h2>
        <p>Ceremony starts at 19:00. Once the video starts playing you can use the scroll bar below to skip to there.</p>
        <ReactPlayer 
          url='https://d2hk0ygkid9qvv.cloudfront.net/streaminghls.m3u8'
          controls
          width='100%'
        />
      </div>
      
      <div>
        <h2>Share a Memory With Us</h2>
        <p>Upload a photo or video by clicking the button below. Please be patient, the upload window may take a few seconds to load.</p>
        <p></p>
        <div className="zoom" onClick={uploadMemory}>
          <a href="javascript:void()">Add a memory</a>
        </div>
      </div>   

    </div>
      
    <div style={{marginBottom: '3em'}}></div>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  const uploadMemory = function() {
    var widget = window.cloudinary.createUploadWidget({
        cloudName: "in-memorial", 
        uploadPreset: "rzpbpeme-parker-public"
    }, (error, result) => { });
    widget.open();
  };

  const loadPlayer = url => {
    this.setState({
      url: 'https://d2hk0ygkid9qvv.cloudfront.net/streaminghls.m3u8',
      played: 0,
      loaded: 0,
      pip: false
    })
  };
  
  return (
    <Layout>
      
      <Helmet>
        <script src="https://widget.cloudinary.com/v2.0/global/all.js" />
        <script src="/memory.js" />
      </Helmet>
      
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        uploadMemory={uploadMemory}
        loadPlayer={loadPlayer}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
